import { useEffect } from 'react';
import { navigate } from 'gatsby';

const DE = () => {
    useEffect(() => {
        navigate('/');
      }, []);
      return null;
}

export default DE
